import React, {useState} from "react";

function useFetchAwaitRefresh<T>(service: () => Promise<T>, initialState: T):[T, boolean, () => void, (t: T) => void]{
    const [state, setState] = useState<T>(initialState)
    const [loading, setLoading] = useState(false)
    const fetchService = async () => {
        setLoading(true)
        const data = await service()
        setState(data)
        setLoading(false)
    }
    React.useEffect(() => {
        fetchService()
    },[])

    return [state, loading, fetchService, setState]
}


export default useFetchAwaitRefresh
