import React, {useContext, useState, useEffect} from 'react';
import {IfoodCategory, IfoodCategoryItem, Order, Product, Status} from "src/types/types"
import Bucket from "./Bucket";
import {Divider, Switch} from "antd";
import {IfoodContext} from "../../../containers/company/contexts/ifoodContext";

interface BucketI {
    products: IfoodCategoryItem[],
    enum: string,
    color: string,
    label: string,
    id: string,
    index: number
}

interface BucketContainer  {
    [key: string]: BucketI
}
interface ProductContainer {
    categories:IfoodCategory[]
}


const createStatusBuckets = (categories: IfoodCategory[] ) => {
    const buckets: BucketContainer = {}
    categories.forEach((ct: IfoodCategory) => {
        buckets[ct.id] = {
            products: ct.items,
            enum: ct.name,
            id: ct.id,
            color: "white",
            index: ct.index,
            label: ct.name
        }
    })
    return buckets
}

const ProductDndContainer = (props:ProductContainer) => {

    const [state, setState] = useState<BucketContainer>(createStatusBuckets(props.categories))
    const {selectProduct} = useContext(IfoodContext)

    useEffect(()=>{
        setState(createStatusBuckets(props.categories))
    },[props.categories])
    return (
        <div style={{marginRight: 12, marginLeft: 12, display: 'flex', flexDirection: 'row'}} >
            <div style={{display: "flex", flexDirection: "column"}}>
                {Object.values(state)
                    .sort((bucketA, bucketB) => bucketA.index - bucketB.index)
                    .map((bucket: BucketI) =>
                    <Bucket
                        updateOrderBucket={() => {}}
                        products={bucket.products}
                        name={bucket.label}
                        id = {bucket.id}
                        enum={bucket.enum}
                        color={bucket.color}
                        onProductSelected = {selectProduct}
                    />)}
            </div>
        </div>

    );
};

export default ProductDndContainer;
