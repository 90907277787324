import React from "react";
import {Table} from 'antd'
import { Ingredient } from "src/types/types";
import useFetchAwaitRefresh from "src/hooks/useFetchAwaitRefresh";
import IngredientsService from "src/services/ingredientsService";
import useAuth from './../../../hooks/useAuth';
import { ColumnsType } from 'antd/es/table';
import { formatMoney } from 'src/utils';





const IngredientsTable = () => {

    const {cToken} = useAuth()

    const [ingredients, loading] = useFetchAwaitRefresh<Ingredient[]>(() =>  IngredientsService.getIngredients(cToken?.token), [])

const columns:ColumnsType<Ingredient> = [
    {
        title:"NOME",   
        dataIndex:"name"
    },
    {
        title:"FORNECEDOR",
        dataIndex:"supplier"
    },
    {
        title:"PREÇO",
        render:(ingredient:Ingredient) => (
            <span>{formatMoney(ingredient.price)}</span>
        )
    }
]

    return (
        <Table dataSource={ingredients}columns={columns} loading={loading}/>
    )
}

export default IngredientsTable;