import React, {FC} from 'react';
import useAuth from "../../hooks/useAuth";
import OrderDetails from 'src/components/OrderDetails/OrderDetails';
const Dashboard:FC = () => {

    return (
        <>
            <span>PÁGINA DASHBOARD</span>
        </>
    );
};

export default Dashboard;
