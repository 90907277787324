import React, {FC} from 'react';
import { useDrag } from 'react-dnd'
import {IfoodCategoryItem} from "src/types/types";
import ProductCard from "../productCard";

const ProductCardDrop:FC<{product: IfoodCategoryItem, onProductSelected: (product: IfoodCategoryItem) => void}> = (props) => {
    console.log(props.product.id)
    const [collected, drag, dragPreview] = useDrag(() => ({
        type: "ORDER",
        item: props.product,
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    }), [props.product])
    return collected.isDragging ? (
        <div ref={dragPreview} />
    ) : (
        <div style = {{margin:10}}ref={drag} {...collected}>
            <ProductCard product={props.product} onProductSelected = {props.onProductSelected}  />
        </div>
    )
};

export default ProductCardDrop;
