import React, {FC} from 'react';
import {ifoodEntregaMenu, ifoodNaMesaMenu} from "src/assets/integration/ifood"
export interface Catalog {
    catalogId: string,
    status: string,
    name: string,
    context: string[]
}

const CatalogCard: FC<{catalog: Catalog, onClick: (catalogId: string) => void}> = (props) => {
    return <div className="card" onClick={() =>props.onClick(props.catalog.catalogId)} style={{margin: 8}}>
        <img src={props.catalog.context[0] === "INDOOR" ? ifoodNaMesaMenu : ifoodEntregaMenu}/>

    </div>
}

const IfoodCatalogs:FC<{catalogs: Catalog[], fetchCatalogItems: (catalogId: string) => void}> = (props) => {

    const {catalogs, fetchCatalogItems} = props

    return (
        <div style={{display: 'flex', margin: 12, justifyContent: 'center'}}>
            {catalogs.map(c => <CatalogCard onClick ={fetchCatalogItems} catalog={c}/>)}
        </div>
    );
};

export default IfoodCatalogs;
