// @ts-nocheck
import React from 'react';
import categoriesService from 'src/services/categoriesService';
import ProductDndContainer from './../../components/Products/dragndrop/ProductDndContainer';
import useFetchAwaitRefresh from './../../hooks/useFetchAwaitRefresh';
import { useContext } from 'react';
import { authContext } from 'src/contexts';
import useAuth from './../../hooks/useAuth';
import { IfoodCategory } from 'src/types/types';
import { Category } from 'src/types/types';
const Products = () => {
    const {cToken} = useAuth()
    const [categories, loading] = useFetchAwaitRefresh<Category[]>(() =>  categoriesService.getCategories(cToken?.token), [])
    console.log(categories)
    return (
        <div>

            <ProductDndContainer categories={categories.map((c:Category) => ({...c, items:c.products}))}/>

         </div>

    );
};

export default Products;
