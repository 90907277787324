import {useAuth} from "src/hooks";
import React, {useContext, useState} from "react";
import {Typography, Tabs} from "antd";
import {IfoodMenu} from "./index"
import { IfoodContext } from "../../contexts/ifoodContext";
import Company from "../../company";
const { Title } = Typography;
const { TabPane } = Tabs;

function callback(key: string) {
    console.log(key);
}

const IfoodIntegration = () => {
    const {company, cToken} = useAuth()
    const {Component} = useContext(IfoodContext)
    const [showMenuModal, setShowMenuModal] = useState(false)

    console.log(Component)

    return (
        <div style={{display: "flex", flexDirection:'row'}}>
            <div style={{ marginTop: 16, marginRight:8, padding: 12, flex: 1}} className="card">
                <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="Ifood" key="1">
                    </TabPane>
                    <TabPane tab="Menu" key="2">
                        <IfoodMenu visible={showMenuModal} onClose={() => setShowMenuModal(false)}/>
                    </TabPane>
                    <TabPane tab="Pedidos" key="3">
                        Content of Tab Pane 3
                    </TabPane>
                </Tabs>
            </div>
            {Component && <div style={{ marginTop: 16, marginRight:8, padding: 12}} className="card">
                {Component}
            </div>}
        </div>

    )
}

export default IfoodIntegration
