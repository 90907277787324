import Tabela from './components/ingredientsTable'
import useFetchAwaitRefresh from 'src/hooks/useFetchAwaitRefresh';
import IngredientsService from 'src/services/ingredientsService';
import useAuth from './../../hooks/useAuth';
import { Ingredient } from 'src/types/types';
import IngredientsTable from './components/ingredientsTable'
const Ingredients = () => {

    


    return(
        <div>
            <IngredientsTable/>
        </div>
    )
}
export default Ingredients;