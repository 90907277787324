import axios from "axios"

const CategoriesAPI = axios.create({baseURL: "https://food-business-api.herokuapp.com/categories"})

class CategoriesService {
    
    getCategories = async (cToken:string | undefined) => {
        const {data} = await CategoriesAPI.get("/", {
            headers:{
                Authorization:cToken
            }
        })
        return data
    }
    
}

export default new CategoriesService();