import styled from "styled-components";


interface IOptionText {
    noMargin?: boolean,
}
const OptionText = styled.p<IOptionText>`
    white-space: nowrap;
    font-size: ${props => props.theme.secondaryFontSize};
    margin-bottom: ${props => props.noMargin ? 0 : '1em'};
    color: ${props => props.theme.secondaryTextColor};

`

interface IItemText {
    noMargin?: boolean,
    bold?: boolean
}
const ItemText = styled.p<IItemText>`
  white-space: nowrap;
  font-size: ${props => props.theme.primaryFontSize};
  margin-bottom: ${props => props.noMargin ? 0 : '1em'};
  font-weight: ${props => props.bold ? "bold": "normal"};
  color: ${props => props.theme.primaryTextColor};
`


export {OptionText, ItemText}
