import axios from "axios"

const ClientsAPI = axios.create({baseURL: "https://food-business-api.herokuapp.com/clients"})

class ClientsService {

    getClients = async (cToken:string | undefined) => {
        const {data} = await ClientsAPI.get("/", {
            headers:{
                Authorization:cToken
            }
        })
        return data

    }

}

export default new ClientsService();
