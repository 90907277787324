import React, {FC, useState} from 'react';
import OrderDnDContainer from "./components/dragndrop/OrderDnDContainer";
import useFetchAwaitRefresh from "src/hooks/useFetchAwaitRefresh";
import OrderService from "src/services/orderService";
import {useAuth} from "src/hooks";
import {Group, Order, OrderStatusType, Status} from "src/types/types";
import styled from "styled-components";
import DrawerDetails from "../../components/DrawerDetails/DrawerDetails";
import {Button} from "antd";
import {SA} from "./components/orderHelpers";
import {io, Socket} from "socket.io-client";




const FlexDiv = styled.div`
  flex: 1;
  height: 100%;
  padding: 8px;
`


const OrderQueueDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
`


const OrderQueueButtons:FC<{order?: Order}> = (props) => {
    if(!props.order)
        return <></>

    const {confirm, cancel} = SA[props.order?.status.orderStatusType]

    return <OrderQueueDiv>
        <Button danger={true} type="primary">
            {cancel.label}
        </Button>
        <Button>
            {confirm.label}
        </Button>
    </OrderQueueDiv>
}

const Orders = () => {

   const {cToken} = useAuth()
   const [orders, loading, fetchService, setState] = useFetchAwaitRefresh<Group<Order>[]>(() =>  OrderService.getOrdersInQueue(cToken?.token), [])
   const [status] = useFetchAwaitRefresh<Status[]>(() =>  OrderService.getStatus(cToken?.token), [])
   const [order, setOrder] = useState<Order | undefined>(undefined)

    const [socket, setSocket] = useState<Socket | undefined>(undefined)

    React.useEffect(() => {
        const socket = io("https://food-business-api.herokuapp.com/", {query: {token: cToken?.token || ""}});
        setSocket(socket)
        socket.on("update_queue", (args) => {
            console.log(args)
            setState(args.queue)
        })
    }, [cToken?.token])




   const  updateOrderBucket = async (order: Order, nextStatus: OrderStatusType) => {
       console.log(order, nextStatus)
       await OrderService.updateOrderInQueue(cToken?.token, order, nextStatus)
   }

    return (
        <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'row', flex: 1, backgroundColor: 'white', paddingTop: 24}}>
            <FlexDiv>
                <OrderDnDContainer updateOrderBucket={updateOrderBucket} order={orders} status={status.filter(status => status.showInQueue)} selectOrder ={(order:Order) => setOrder(order)} />
            </FlexDiv>
            <DrawerDetails order={order} visible={!!order} onClose={() => setOrder(undefined)} extra={<OrderQueueButtons order={order}/>}/>
        </div>
    );
};

export default Orders;
