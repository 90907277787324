import React from 'react';
import {AuthProvider} from "src/contexts";
import {Router} from "src/router";
import './App.less';
import {ThemeProvider} from "styled-components";
import {DefaultTheme} from 'styled-components'


const theme: DefaultTheme = {
    primaryFontSize: '16px',
    secondaryFontSize: '14',
    primaryColor: '#ED5643',
    secondaryColor: '#FFF',
    primaryTextColor: '#000',
    secondaryTextColor: 'rgb(113, 113, 113)'

}

function App() {
  return (
    <AuthProvider>
        <ThemeProvider theme={theme} >
            <Router/>
        </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
