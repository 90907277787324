import {createContext, FC} from "react";
import {Company, User, cToken} from "../types/types";
import createPersistedState from 'use-persisted-state';
import authService from "../services/authService";

interface IAuthContext {
    user?: User,
    company?: Company,
    cToken?: cToken,
    uToken?: string,
    loginUser: (username: string, password: string) => Promise<User | null>,
    loginCompany: (companyId: string, userId: string) => Promise<boolean>
}

const useUserState = createPersistedState('user');
const useCompanyState = createPersistedState('company');
const useUTokenState = createPersistedState('u-token');
const useCTokenState = createPersistedState('c-token');

const defaultValue: IAuthContext = {
    loginUser: async (username, password) => null,
    loginCompany: async (companyId, userId) => false
}

const authContext = createContext<IAuthContext>(defaultValue);

const AuthProvider: FC = ({ children }) => {
    const [user, setUser] = useUserState();
    const [company, setCompany] = useCompanyState()
    const [cToken, setCToken] = useCTokenState()
    const [uToken, setUToken] = useUTokenState()

    const loginUser = async(username: string, password: string) => {
        console.log(username, password)
        const {authorized, user, uToken} = await authService.loginUser(username, password)
        if(authorized) {
            setUser(user)
            setUToken(uToken)
        }
        return user
    };
    
    const loginCompany = async(companyId: string, userId: string) => {
        console.log(companyId, userId)
        const {authorized, cToken, company} = await authService.loginCompany(companyId, userId)
        if(authorized) {
            setCToken(cToken)
            setCompany(company)
        }
        return company
    };

    return (
        <authContext.Provider value={{user, company, cToken, uToken, loginUser, loginCompany}}>
            {children}
            </authContext.Provider>
    );
}

export {AuthProvider, authContext}
