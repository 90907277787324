import React from 'react';
import {useAuth} from "../../hooks";

import {CompanyInfo, CompanyIntegrations, IfoodIntegration} from "./components"
import {IfoodContextProvider} from "./contexts/ifoodContext";

const Company = () => {

    const {company, cToken} = useAuth()

    return (
        <div>
            <div style={{display: 'flex', flex:1}}>
                <div className= "card" style={{display: "flex", flexDirection: 'column', flex:1/3, marginRight: 8}}>
                    <CompanyInfo company={company}/>
                </div>
                <div className= "card" style={{display: "flex", flexDirection: 'column', flex:2/3}}>
                    <CompanyIntegrations/>
                </div>
            </div>
            <IfoodContextProvider>
                <IfoodIntegration/>
            </IfoodContextProvider>
        </div>

    );
};

export default Company;
