import React, {FC} from 'react';
import {Card, Image} from 'antd'
import {IfoodCategoryItem} from "src/types/types";
import { Button, Radio } from 'antd';

const { Meta } = Card;

const ProductCard: FC<{ product: IfoodCategoryItem, onProductSelected: (product: IfoodCategoryItem) => void}> = (props) => {
    return (
        <div style={styles.card} onClick={() => props.onProductSelected(props.product)} >
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Image src = {props.product.pendent && props.product.imagePath ? `https://static-images.ifood.com.br/pratos/${props.product.imagePath}`:props.product.imagePath} style={styles.image}/>
                <div style={{paddingLeft: 16, paddingRight:8}}>
                    <Meta
                          title={<b style={{whiteSpace: "normal"}}>{props.product.name}</b>}
                          description={<span>{props.product.description} {!props.product.pendent && '\u00A0'}</span>}
                    />
                    {props.product.pendent && <div style={{display: 'flex', justifyContent: 'space-between', flex: 1}}>
                        <Radio.Group >
                            <Radio.Button value="ingredient">Ingrediente</Radio.Button>
                            <Radio.Button value="delete">Descartar</Radio.Button>
                        </Radio.Group>
                    </div>}

                </div>

            </div>
        </div>

    );
};


const styles  = {
    image: {
        height: '80px',
        maxHeight: '80px',
        backgroundPosition: '50%',
        backgroundSize: 'cover',
        borderRadius: '6px',
        width: '80px'

    },
    card: {
        boxShadow: "1px 1px 2px #091e4240",
        transition: "0.3s",
        borderRadius:"5px",
        background: "#fff",
        padding: 8
    }
}

export default ProductCard;
