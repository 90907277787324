import React, {useState, useEffect} from "react";
import Details from "./components/Details";
import OrderService from "src/services/orderService";
import useFetchAwaitRefresh from "src/hooks/useFetchAwaitRefresh";
import { Order } from "src/types/types";
import { useAuth } from "src/hooks";
import { IOrderDetails } from "./components/Details";

const OrderDetails = (props:IOrderDetails) => {
    console.log(props.order)
    return (
        <>
            <Details order={props.order} loading={props.loading}/>
        </>
    )
}

export default OrderDetails;