import {Input, Typography} from "antd";
import React from "react";
const { Title } = Typography;

const CompanyIntegrations = () => {
    return <div style={{margin: 12}}>
        <div style={{marginBottom: 4}}>
            <Title level={4}>
                Integrações
            </Title>
        </div>
        <div>
            <Input.Search  style={{marginBottom: 4}} placeholder={"ID IFOOD"}            enterButton="Conectar"/>
            <Input.Search placeholder={"ID IFOOD"}            enterButton="Conectar"/>
        </div>

    </div>
}

export default CompanyIntegrations
