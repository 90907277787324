import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import {Login, Dashboard, Products, Orders, Company, OrdersHistory, Ingredients, Clients} from '../containers'
import {PrivateRoute} from "./index"
import {Menu} from "../components";


const Router = () =>{
    return(
        <BrowserRouter>
            <Switch>
                <Route exact path="/">
                    <Redirect to="/dashboard" />
                </Route>
                <Route component={Login} path='/login' exact={true} />
                <Menu>
                    <Switch>
                        <PrivateRoute path='/dashboard' exact  >
                            <Dashboard/>
                        </PrivateRoute>
                        <PrivateRoute path='/products' exact  >
                            <Products/>
                        </PrivateRoute>
                        <PrivateRoute path='/orders' exact  >
                            <Orders/>
                        </PrivateRoute>
                        <PrivateRoute path='/company' exact  >
                            <Company/>
                        </PrivateRoute>
                        <PrivateRoute path='/history' exact  >
                            <OrdersHistory />
                        </PrivateRoute>
                        <PrivateRoute path='/clients' exact  >
                            <Clients />
                        </PrivateRoute>
                        <PrivateRoute path='/ingredients' exact  >
                            <Ingredients/>
                        </PrivateRoute>
                        <PrivateRoute path='/clients' exact >
                            <Clients />
                        </PrivateRoute>
                    </Switch>
                </Menu>
            </Switch>
        </BrowserRouter>
    )
}

export default Router;
