import React, {useState} from 'react';
import {Group, Order, OrderStatusType, Status} from "src/types/types"
import Bucket from "./Bucket";
import {Switch} from "antd";

interface BucketI {
    orders: Order[],
    enum: string,
    color: string,
    label: string,
    id: number
}

interface BucketContainer  {
    [key: string]: BucketI
}
interface OrderContainer {
    order: Group<Order>[],
    status:Status[],
    selectOrder(order:Order): void
    updateOrderBucket(order: Order, nextStatus: OrderStatusType): void
}


const OrderDnDContainer = (props:OrderContainer) => {

    return (
            <div style={{display: "flex", flexDirection: "row", height: '100%'}}>
                 {props.order.map(group => {
                     const groupStatus = props.status.find(st => st.orderStatusType === group.index)
                     if(!groupStatus)
                         return <></>
                     return (
                         <Bucket hideItems={true}
                                 updateOrderBucket={props.updateOrderBucket}
                                 orders={group.values}
                                 name={groupStatus.name}
                                 selectOrder = {props.selectOrder}
                                 id = {groupStatus.id}
                                 enum={groupStatus.name}
                                 color={groupStatus.color}
                         />
                     )
                 })}


            </div>

    );
};

export default OrderDnDContainer;
