import { Table, Space } from 'antd'
import React, {useContext} from 'react'
import dayjs from 'dayjs';
import { IfoodOrderItem, Order } from 'src/types/types';
import { CreditCardOutlined } from '@ant-design/icons';
import { formatMoney } from 'src/utils';
import './Details.css'
import  StatusTag from 'src/components/StatusTag/StatusTag';
import { Reducer } from 'src/utils/reducer';
import { ThemeContext } from 'styled-components';
import {OptionText, ItemText} from "./StyledDetails";

export interface IOrderDetails {
    order?:Order
    loading:boolean
}


const OrderLabelText = (props: {label: string, text: string, bold?: boolean}) => {
    return <div style = {{display: 'flex', justifyContent: 'space-between'}}>
        <ItemText bold={props.bold}>{props.label}</ItemText>
        <ItemText bold={props.bold}>{props.text}</ItemText>
    </div>
}

const columns = [
    {
        title: () => <ItemText  bold={true} noMargin={true}>Item</ItemText>,
        render:(item:IfoodOrderItem) =>(
            <div>
                <ItemText>{item.name}</ItemText>
                {item.options ? item.options.map(complement => <OptionText>{complement.name}</OptionText> ) : ""}
            </div>
        )
    },
    {
        title: () => <ItemText bold={true} noMargin={true}>Qtde</ItemText>,
        render:(item:IfoodOrderItem) =>(
            <div>
                <ItemText>{item.quantity}</ItemText>
                {item.options ? item.options.map(complement => <OptionText>{complement.quantity}</OptionText> ) : ""}
            </div>
        )
    },
    {
        title: () => <ItemText bold={true} noMargin={true}>Valor unitário</ItemText>,
        render:(item:IfoodOrderItem) =>(
            <div>
                <ItemText>{formatMoney(item.unitPrice)}</ItemText>
                {item.options ? item.options.map(complement => <OptionText>{formatMoney(complement.unitPrice)}</OptionText> ) : ""}
            </div>
        )
    },
    {
        title: () => <ItemText bold={true} noMargin={true}>Subtotal</ItemText>,
        render:(item:IfoodOrderItem) => (
            <div>
                <ItemText>{formatMoney(item.price)}</ItemText>
                {item.options ? item.options.map(complement => <OptionText>{formatMoney(complement.price)}</OptionText> ) : ""}
            </div>
        )
    }
]



const OrderHeader = (props: {order?: Order}) => {
    const orderDate = dayjs(props.order?.createdAt).format("DD MMM H:mm")

    const themeContext = useContext(ThemeContext);

    return <div id="order-details">
        <Space size="middle">
            <ItemText noMargin={true}>{`Pedido nº ${props.order?.id}(id)`}</ItemText>
            <StatusTag color={props.order?.status.color} name={props.order?.status.name}/>
        </Space>
        <br/>
        <OptionText>{orderDate}</OptionText>
        <div id="payment-method">
            <ItemText bold={true} noMargin={true}>Forma de Pagamento</ItemText>
            <div style={{display: 'flex', marginLeft: 4, alignItems: 'center', marginBottom: '1em'}}>
                <CreditCardOutlined style={{fontSize: themeContext.primaryFontSize, marginRight: 8}} />
                <ItemText noMargin={true}> Crédito via app do iFood </ItemText>
            </div>
        </div>
    </div>
}

const OrderItems = (props: IOrderDetails) => {

    return <div id="items-details">
        <ItemText bold={true}>Detalhes</ItemText>
        <Table columns={columns} className={"custom-table"} dataSource={props.order?.orderItems} loading={props.loading} pagination={false}/>
    </div>
}

const OrderInfo = (props: {order?: Order}) => {
    const nItens = props.order?.orderItems.length === 1 ? "1 item" : `${props.order?.orderItems.length} itens`
    const subTotal = Reducer(props?.order?.orderItems)
    return (
        <div style={{marginTop: 8}}>
            <OrderLabelText label={`${nItens} | Subtotal:`} text={formatMoney(subTotal)}/>
            <OrderLabelText label={`Taxa de Entrega:`} text={formatMoney(props.order?.deliveryFee)}/>
            <OrderLabelText label={`Total do pedido:`} text = {formatMoney(props.order?.total)} bold = {true}/>
        </div>
    )
}



const Details = (props:IOrderDetails) => {
    return(
            <div>
                <OrderHeader order={props.order}/>
                <OrderItems loading={props.loading} order={props.order}/>
                <OrderInfo order={props.order}/>
            </div>

    )
}

export default Details;
