const formatDays = (days: number) => {
    if(days === 0)
        return "Mesmo dia"
    else if(days === 1)
        return  `${days} dia`
    else return `${days} dias`
}

const formatPercent = (percent: number) => {
    return `${percent}%`
}

const formatMoney = (value: number | undefined) => {
    if(value === undefined)
        return ""
    const formatted =  value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
    if(value === 0)
        return formatted.concat(" (Grátis)")
    return formatted
}

export {formatMoney, formatPercent, formatDays}
