import axios from "axios"

const AuthAPI = axios.create({baseURL: "https://food-user-api.herokuapp.com/auth"})

class AuthService {

    loginUser = async (username: string, password: string) => {
        const {data} = await AuthAPI.post("/login/user", {username, password})
        const {token, user} = data
        if(token && user)
            return {
                authorized: true,
                uToken: token,
                user
            }
        return  {authorized: false}
    }
    loginCompany = async (companyId:string, userId:string) => {
        const {data} = await AuthAPI.post(`/login/company`, {companyId, userId})
        const {token, company} = data
        if(token){
            return {
                authorized: true,
                cToken: token,
                company
            }
        }
        return {authorized:false}

    }
}

export default new AuthService()
