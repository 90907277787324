import React, {FC, useState} from 'react';
import {Modal, Steps} from "antd"
import useFetchAwaitRefresh from "src/hooks/useFetchAwaitRefresh";
import ifoodService from "src/services/ifoodService";
import {useAuth} from "src/hooks";
import {IfoodCatalogs, IfoodMenuCategories} from "./index";
import {Catalog} from "./ifoodCatalogs";
const { Step } = Steps;

interface ModalProps {
    visible: boolean,
    onClose: () => void
}

const Stepper:FC<{current: number}> = (props) => {
    if(!props.children)
        return <span>Componente não encontrado</span>
    const CurrentChildren = React.Children.toArray(props.children)[props.current]
    if(!CurrentChildren)
        return <span>Componente não encontrado</span>
    return <>{CurrentChildren}</>
}

const IfoodMenu:FC<ModalProps> = (props) => {

    const {company, cToken} = useAuth()
    const [current, setCurrent] = useState(0)
    const [catalog, loading] = useFetchAwaitRefresh<Catalog[]>(() =>  ifoodService.getCatalogs(cToken?.token), [])
    const [menu, setMenu] = useState([])

    const fetchCatalogItems = async  (catalogId: string) => {
        const categories = await ifoodService.getCategoriesByCatalog(cToken?.token, catalogId)
        setMenu(categories)
        setCurrent(current => current + 1)
        console.log(categories)
    }

    const onChangeStep = (step: number) => {
        setCurrent(step)
    }

    return (
        <div >
            <Steps current={current} onChange={onChangeStep} style={{margin: 8}}>
                <Step title="Catalogo" description="Escolha o catalogo" />
                <Step title="Menu" description="Ajustar o menu" />
                <Step title="Finalizar" description="Finalzar importação" />
            </Steps>
            <Stepper current={current}  >
                <IfoodCatalogs catalogs = {catalog} fetchCatalogItems = {fetchCatalogItems} />
                <IfoodMenuCategories menu = {menu}/>
            </Stepper>
        </div>
    );
};

export default IfoodMenu;
