import styled from 'styled-components';


interface IFlexColumn {
    justifyContent?:string
}

const FlexColumn = styled.div<IFlexColumn>`
    display:flex;
    flex-direction:column;
    justify-content:${props => props.justifyContent ? props.justifyContent : "center"};
`


export {FlexColumn}