import React, {FC} from "react";
import {Layout, Button, Typography} from "antd";
import {SideNav} from "./index";
import "./menu.css"
import {useAuth} from "../../hooks";
import { FlexColumn } from "./StyledMenu";
const {Header, Content} = Layout;

const Menu:FC = (props) =>{

    const {company, user} = useAuth()

    return(
        <Layout>
            <SideNav/>
            <Layout >
                <Header  style={{justifyContent:'space-between', display:'flex', alignItems:'center', backgroundColor: 'white', borderBottom:"1px solid #E7E7E7", boxShadow: "4px red"}}>
                    <div className="header-separator"></div>
                    <FlexColumn>
                        <Typography.Title level={5}> {company?.name}</Typography.Title>
                        <Typography.Text style={{lineHeight: "normal"}}>{user?.firstName}</Typography.Text>
                    </FlexColumn>

                </Header>
                <Content  style={{minHeight: "calc(100vh - 112px)"}}>
                    {props.children}
                </Content>
            </Layout>
        </Layout>
    )
}






export default Menu;
