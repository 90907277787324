import { IfoodOrderItem } from "src/types/types"



const Reducer = (array?:IfoodOrderItem[]) => {

    const reducer = (previous: number, current: IfoodOrderItem) => previous + current.totalPrice
    return array?.reduce(reducer, 0)
}

export {Reducer}