enum OrderStatusType {
    WAITING_CONFIRMATION = "WAITING_CONFIRMATION",
    IN_PROGRESS = "IN_PROGRESS",
    WAITING_FOR_PICKUP = "WAITING_FOR_PICKUP",
    IN_DELIVERY = "IN_DELIVERY",
    CANCELED = "CANCELED",
    INTEGRATION_CANCELED = "INTEGRATION_CANCELED",
    FINISHED = "FINISHED",
    CUSTOM = "CUSTOM",
}

interface Action {
    nextStatus: OrderStatusType,
    label: string,
    danger?: boolean
}


type StatusActions = {
    [key in OrderStatusType]: {
        quickAction: Action[],
        confirm: Action,
        cancel: Action
    }
}

const SA: StatusActions = {
    [OrderStatusType.WAITING_CONFIRMATION]: {
        cancel: {
            nextStatus: OrderStatusType.CANCELED,
            label: "Cancelar pedido"
        },
        confirm: {
            nextStatus: OrderStatusType.IN_PROGRESS,
            label: "Confirmar preparo"
        },
        quickAction: [
            {
                nextStatus: OrderStatusType.CANCELED,
                label: "Cancelar",
                danger: true
            },
            {
                nextStatus: OrderStatusType.IN_PROGRESS,
                label: "Confirmar"
            }
        ]
    },
    [OrderStatusType.CANCELED]: {
        cancel: {
            nextStatus: OrderStatusType.CANCELED,

            label: "Cancelar pedido"
        },
        confirm: {
            nextStatus: OrderStatusType.CANCELED,
            label: "Confirmar prepaaro"
        },
        quickAction: [
            {
                nextStatus: OrderStatusType.CANCELED,
                label: "Confirmar"
            }
        ]
    },
    [OrderStatusType.CUSTOM]: {
        cancel: {
            nextStatus: OrderStatusType.CANCELED,
            label: "Cancelar pedido"
        },
        confirm: {
            nextStatus: OrderStatusType.CANCELED,
            label: "Confirmar prepaaro"
        },
        quickAction: [
            {
                nextStatus: OrderStatusType.CANCELED,
                label: "Confirmar"
            }
        ]
    },
    [OrderStatusType.FINISHED]: {
        cancel: {
            nextStatus: OrderStatusType.CANCELED,
            label: "Cancelar pedido"
        },
        confirm: {
            nextStatus: OrderStatusType.CANCELED,
            label: "Confirmar prepaaro"
        },
        quickAction: [
            {
                nextStatus: OrderStatusType.CANCELED,
                label: "Confirmar"
            }
        ]
    },
    [OrderStatusType.IN_DELIVERY]: {
        cancel: {
            nextStatus: OrderStatusType.CANCELED,
            label: "Cancelar pedido"
        },
        confirm: {
            nextStatus: OrderStatusType.FINISHED,
            label: "Finalizar"
        },
        quickAction: [
            {
                nextStatus: OrderStatusType.FINISHED,
                label: "Finalizar"
            }
        ]
    },
    [OrderStatusType.IN_PROGRESS]: {
        cancel: {
            nextStatus: OrderStatusType.CANCELED,
            label: "Cancelar pedido"
        },
        confirm: {
            nextStatus: OrderStatusType.IN_DELIVERY,
            label: "Despachar"
        },
        quickAction: [
            {
                nextStatus: OrderStatusType.IN_DELIVERY,
                label: "Despachar"
            }
        ]
    },
    [OrderStatusType.INTEGRATION_CANCELED]: {
        cancel: {
            nextStatus: OrderStatusType.CANCELED,
            label: "Cancelar pedido"
        },
        confirm: {
            nextStatus: OrderStatusType.CANCELED,
            label: "Confirmar prepaaro"
        },
        quickAction: [
            {
                nextStatus: OrderStatusType.CANCELED,
                label: "Confirmar"
            }
        ]
    },
    [OrderStatusType.WAITING_FOR_PICKUP]: {
        cancel: {
            nextStatus: OrderStatusType.CANCELED,
            label: "Cancelar pedido"
        },
        confirm: {
            nextStatus: OrderStatusType.CANCELED,
            label: "Confirmar prepaaro"
        },
        quickAction: [
            {
                nextStatus: OrderStatusType.CANCELED,
                label: "Confirmar"
            }
        ]
    }

}

export {SA}
