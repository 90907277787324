import React from 'react'
import { Table, Typography, Skeleton, Empty, Button } from 'antd';
import { Order } from 'src/types/types';
import  Images  from 'src/assets/integration';
import { formatMoney } from 'src/utils';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';
import {ShopOutlined, HomeOutlined, CreditCardOutlined} from "@ant-design/icons";
import StatusTag from 'src/components/StatusTag/StatusTag';

const {Title} = Typography;


interface OrderContainer {
    title: string,
    orders: Order[],
    loading: boolean
    onDetails(order: Order):void
}

const HistoryTable = (props:OrderContainer) => {

    const {title, orders, loading} = props
    const columns:ColumnsType<Order> = [
        {
            title:"ID",
            render:(order:Order) => (
                <span>{order.id}</span>
            )
        },
        {
            title:"Origem",
            render:(order:Order) => (
                <div>
                     <img height={64} width={64}   src={Images[order.origin]}/>
                </div>
            )
        },
        {
            title:"Forma de Entrega",
            dataIndex:"type",
            render:(order:Order) => (
                order.type === "delivery" ? <HomeOutlined style={{fontSize: 20}}/> : <ShopOutlined style={{fontSize: 20}}/>
            )
        },
        {
            title:"Método de Pagamento",
            dataIndex:"paymentMethod",
            render: () => <CreditCardOutlined style={{fontSize: 20}} />
        },
        {
            title:"Status do Pedido",
            render:(order:Order) => <StatusTag color={order.status.color} name={order.status.name}/>
        },
        {
            title:"Valor do Pedido",
            render:(order:Order) => (
                <span style={{fontSize:16}}>{formatMoney(order.total)}</span>
            )
        },
        {
            title:"Horário do Pedido",
            render:(order:Order) => (
                <div style={{display:"flex", flexDirection:"column"}}>
                    <span style={{fontSize:16}}>{dayjs(order.createdAt).format("HH:mm")}</span>
                </div>
            )
        },
        {
            title:"",
            render:(order:Order) => (
                <Button onClick={() => props.onDetails(order)}>
                    DETALHES
                </Button>
            )
        }
    ]

    return (
        <div>
            <div style={{display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center", margin:24}}>
                <Title code level={3}>{title}</Title>
            </div>
            <Table<Order> pagination={false} columns={columns} dataSource={orders} rowKey="id" locale={{emptyText: loading ? <Skeleton active={true} /> : <Empty />}}/>
        </div>
    )
}

export default HistoryTable;
