import OrderDetails from "../OrderDetails/OrderDetails";
import { Drawer, DrawerProps, Button, Space } from "antd";
import React, {ReactNode, useState} from "react";
import { IOrderDetails } from './../OrderDetails/components/Details';
import { Order } from 'src/types/types';
import styled from "styled-components";

interface IDrawer {
    onClose():void
    visible:boolean
    order?:Order | undefined,
    extra?: ReactNode

}
const Separator = styled.div`
  width: 100%;
  border-bottom: 1px solid #E7E7E7
`

const DrawerDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
`

const DrawerDetails = (props:IDrawer) => {

    return(
        <Drawer
        title="Detalhes do Pedido"
        placement="right"
        width={672}
        headerStyle={{height: '64px'}}
        onClose={props.onClose}
        visible={props.visible}
      >
            <DrawerDiv>
                <OrderDetails order={props.order} loading={false}/>
                <div>
                    <Separator/>
                    {props.extra}
                </div>
            </DrawerDiv>

      </Drawer>
    )
}

export default DrawerDetails;
