import axios from "axios"

const IfoodAPI = axios.create({baseURL: "https://food-integration-api.herokuapp.com/ifood"})

class IfoodService {

    getCatalogs = async (cToken:string | undefined) => {
        const {data} = await IfoodAPI.get("/catalogs", {
            headers:{
                Authorization:cToken
            }
        })
        return data

    }

    getCategoriesByCatalog = async (cToken:string | undefined, catalogId: string) => {
        const {data} = await IfoodAPI.get(`/catalogs/menus/${catalogId}`, {
            headers:{
                Authorization:cToken
            }
        })
        return data

    }
}

export default new IfoodService();
