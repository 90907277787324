import React, {FC} from "react";
import {Empty, Image, Skeleton, Table} from 'antd'
import {ClientOrder, Order} from "src/types/types";
import { ColumnsType } from 'antd/es/table';
import  Images  from 'src/assets/integration';
import dayjs from "dayjs";


interface IClientsTable {
    clients: ClientOrder[]
    loading: boolean
}

const columns:ColumnsType<ClientOrder> = [
    {
        title:"NOME",
        dataIndex:"client_name"
    },
    {
        title:"Origem do cliente",
        render:(client:ClientOrder) => (
            <div>
                <Image preview={false} height={48} width={48}  src={Images[client.client_origin]}/>
            </div>
        )
    },
    {
        title:"Ultimo pedido",
        render:(client:ClientOrder) => (
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <Image preview={false} height={48} width={48} src={Images[client.lastOrderOrigin]}/>
                <p style={{fontSize:16, marginBottom: 0}}>em {dayjs(client.lastOrderDate).format("DD/MM")}</p>
            </div>
        )
    }
]

const ClientsTable:FC<IClientsTable> = (props) => {
    const {clients, loading} = props
    return (
        <Table dataSource={clients}columns={columns}    locale={{
            emptyText: loading ? <Skeleton active={true} /> : <Empty />
        }}/>
    )
}

export default ClientsTable;
