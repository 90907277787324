import React, {FC, useState} from 'react';
import OrderCard from "../orderCard";
import {Order, OrderStatusType} from "src/types/types";
import {Button} from 'antd'
import {SA} from "../orderHelpers";

interface OCD {
    selectOrder(order:Order): void
    order: Order
    hideItems: boolean,
    updateOrderBucket(order: Order, nextStatus: OrderStatusType): void

}

const OrderQuickButtons:FC<{order: Order, updateOrderBucket(order: Order, nextStatus: OrderStatusType): void}> = (props) => {

    const {quickAction} = SA[props.order.status.orderStatusType]
    const [loading, setLoading] = useState(false)

    return <div>
        {quickAction.map((qa, i) =>
            <Button onClick={async (e) => {
                setLoading(true)
                 e.stopPropagation()
                e.currentTarget.blur()
                await props.updateOrderBucket(props.order, qa.nextStatus)
                setLoading(false)
            }} loading={loading} danger={qa.danger} style={{marginLeft: i !==0 ? 4 : 0}} type = {qa.danger ? "primary" : undefined } >{qa.label}</Button>)}

    </div>
}

const OrderCardDrop:FC<OCD> = (props) => {
    return <div style = {{margin:10}} onClick={() => props.selectOrder(props.order)}>
            <OrderCard order={props.order} hideItems={props.hideItems} actions={<OrderQuickButtons order={props.order} updateOrderBucket={props.updateOrderBucket} />}/>
        </div>

};

export default OrderCardDrop;
