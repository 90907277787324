import React, {FC} from "react";
import OrderCardDrop from "./orderCardDrop";
import {Order, OrderStatusType} from "../../../../types/types";

interface BucketProps {
    name: string,
    enum: string,
    hideItems: boolean,
    color: string,
    id: number
    orders: Order[],
    selectOrder(order:Order): void,
    updateOrderBucket(order: Order, nextStatus: OrderStatusType): void
}

const Bucket:FC<BucketProps> = (props) => {
    return (
        <div
            role={props.enum}
            style={{ flex: 1, backgroundColor: "white", borderRight:"1px solid #E7E7E7", minHeight: '100%'}}
        >
            <div style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4, padding:10, marginBottom: '0px'}}>
                <p style={{fontWeight: "bold", }}>{props.name}</p>
            </div>
            {props.orders.map(c => <OrderCardDrop  updateOrderBucket={props.updateOrderBucket} order={c} hideItems={props.hideItems} selectOrder = {props.selectOrder}/>)}
        </div>
    )
}

export default Bucket
