import React, {FC} from "react";
import {useDrop} from 'react-dnd'
import ProductCardDrop from "./productCardDrop";
import {IfoodCategoryItem} from "src/types/types";
import {Divider, Typography, List} from "antd";

interface BucketProps {
    name: string,
    enum: string,
    color: string,
    id: string
    products: IfoodCategoryItem[],
    onProductSelected: (product: IfoodCategoryItem) => void,
    updateOrderBucket: (product: any, statusId: string) => void
}


const Bucket:FC<BucketProps> = (props) => {
    const [{isOver}, drop] = useDrop(() => ({
        // The type (or types) to accept - strings or symbols
        accept: 'PRODUCT',
        // Props to collect
        drop(item) {props.updateOrderBucket(item, props.id.toString())},
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })

    }))
    console.log(props.products)
    if(props.products === undefined)
        return <></>
    
    return (
        <>
        <div style={styles.card}>
            <Typography.Title level={3} style={{marginLeft: 8}}>{props.name}</Typography.Title>
            <div
                ref={drop}
                role={props.enum}
                style={{  height: 'fit-content'}}
            >
                <List
                    grid={{ gutter: 6, xs: 4, sm: 4, md: 4, lg: 4, xl: 4, xxl: 4 }}
                    dataSource={props.products}
                    renderItem={c => (
                        <List.Item>
                            <ProductCardDrop product={c} onProductSelected = {props.onProductSelected} />
                        </List.Item>
                    )}
                />
            </div>
        </div>
        <Divider/>
        </>


            )
}

const styles  = {
    card: {
        transition: "0.3s",
        borderRadius:"5px",
        background: "#fff",
        margin: 8
    }
}

export default Bucket
