import HistoryTable from "./components/historyTable";
import FilterHistory from "./components/FilterHistory";
import {message, Breadcrumb, Typography} from 'antd';
import OrderService from "src/services/orderService";
import useAuth from 'src/hooks/useAuth';
import { OrdersFilters } from 'src/services/orderService';
import { Order, Group } from "src/types/types";
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs'
import { objectIsEmpty } from "src/utils";
import DrawerDetails from "src/components/DrawerDetails/DrawerDetails";
import {useHistory} from "react-router";

const OrdersHistory = () =>{
    const {cToken} = useAuth()
    const [orders, setOrders] = useState<Group<Order>[]>([{index: dayjs().format("DD-MM-YYYY"), values:[]}])
    const [loading, setLoading] = useState(false)
    const [filter,setFilter] = useState<Partial<OrdersFilters>>({})
    const [visible, setVisible] = useState(false)
    const [order, setOrder] = useState<Order | undefined>(undefined)
    const history = useHistory()

    const updateFilter = (key:string) => (value:string | Dayjs | null) => {
          setFilter(filter => ({...filter, [key]: value}))
    }
    const CloseDrawer = () =>{
        setVisible(false)
    }

    const getOrders = async () =>{
        setLoading(true)
        const filteredOrders = await OrderService.getFilteredOrders(cToken?.token, {...filter, groupBy: true})
        setOrders(filteredOrders)
        if(!filteredOrders.length)
            message.error("NENHUM PEDIDO ENCONTRADO")
        console.log(filteredOrders)
        setLoading(false)
    }

    const showOrderDetails = async (order: Order) => {
        setVisible(true)
        setOrder(order)
        const orderDetails = await OrderService.getOrderDetails(cToken?.token, order.id)
        setOrder(orderDetails)
    }

    useEffect(()=> {
        if(objectIsEmpty(filter))
            getOrders()
    },[filter])


    return(
        <div  style={{padding:24, backgroundColor: 'white'}}>
            <FilterHistory updateFilter={updateFilter} filterOrders={getOrders}/>
            {orders.map(group => <HistoryTable loading={loading} key = {group.index} orders={group.values} title = {group.index} onDetails={showOrderDetails}/>)}
            <DrawerDetails visible={visible} onClose={CloseDrawer} order={order}/>
        </div>
    )
}

export default OrdersHistory
