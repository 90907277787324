import axios from "axios"
import {Group, Order, OrderStatusType} from "src/types/types"

export const API_PROD_URL = "https://food-business-api.herokuapp.com/orders"

const API_LOCAL_URL =  "http://localhost:8001/orders"

const OrdersAPI = axios.create({baseURL: API_PROD_URL})
export interface OrdersFilters {
    orderNumber?:number
    beforeDate?:string
    afterDate?:string
    groupBy?: boolean
}

class OrderService {

    getOrders = async (cToken:string | undefined) => {
        const {data} = await OrdersAPI.get("/", {
            headers:{
                Authorization:cToken
            }
        })
        return data
    }

    getOrdersInQueue = async (cToken:string | undefined) => {
        const {data} = await OrdersAPI.get("/queue", {
            headers:{
                Authorization:cToken
            }
        })
        return data
    }

    getStatus = async (cToken:string | undefined) => {
        const {data} = await OrdersAPI.get("/status", {
            headers:{
                Authorization:cToken
            }
        })
        return data
    }
    getFilteredOrders = async (cToken:string | undefined, filters:OrdersFilters):Promise<Group<Order>[]> => {
        const {data} = await OrdersAPI.get("/filter", {params:{
            beforeDate:filters.beforeDate,
            afterDate:filters.afterDate,
            orderNumber:filters.orderNumber,
            groupBy: filters.groupBy
        },
            headers:{
                Authorization:cToken
            }
        })
        return data
    }
    getOrderDetails = async (cToken:string | undefined, orderId:number | string):Promise<Order> => {
        const {data} = await OrdersAPI.get("/details", {params:{
            orderId
        },
            headers:{
                Authorization:cToken
            }
        })
        return data
    }

    updateOrderInQueue = async (cToken:string | undefined, order: Order, nextStatus: OrderStatusType) => {
        const {data} = await OrdersAPI.put("/queue", {order, nextStatus}, {
            headers:{
                Authorization:cToken
            }
        })
        return !!data
    }

}

export default new OrderService();
