import React, {useEffect, useState} from 'react'
import './login.css'
import { Form, Input, Button, Image, message } from'antd'
import useAuth from "../../hooks/useAuth";
import {useHistory} from "react-router-dom"
import foobi from 'src/assets/logo/img.png'

const Login = () =>{

    const { loginUser,loginCompany, user} = useAuth()
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    interface ValuesLogin{
        username:string,
        password:string
    }


    const login = async (values: ValuesLogin) =>{
        setLoading(true)
        const user =  await loginUser(values.username, values.password)
        if(!user){
            message.error("Erro, otario!")
        }
        else{
            const authorized = await loginCompany(user.companies[0], user._id)
            if(!authorized){
                message.error("EMPRESA TE CORTOU OTARIO(demitido)")
            }
            else{
                console.log(authorized)
                history.push("/dashboard")
            }

        }
    }

    return(
        <div style={styles.loginPage}>
            <Form<ValuesLogin> onFinish={login} style={styles.form} className="card">
                <img src={foobi} style={{height:110, width: 220}}/>

                <Form.Item name="username" rules={[{required: true, message: 'Insira o usuario！'}]}>
                    <Input  placeholder="Usuario"/>
                </Form.Item>
                <Form.Item name="password" rules={[{required: true, message: 'Insira a senha！'}]}>
                    <Input type="password" placeholder="Senha"/>
                </Form.Item>
                <Form.Item>
                    <Button loading={loading} htmlType="submit" type="primary" style={styles.button}>
                        Entrar
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
const styles = {
    loginPage: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#fff",
        height:'100%'
    },
    form: {
        width: "300px",
        padding: "50px 40px 10px",
        borderRadius: "10px",
    },
    text: {
        textAlign: "center"

    },
    button: {
        width: " 100%"
    }
}

export default Login;
