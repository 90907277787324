import {createContext, FC, ReactElement, ReactNode, useState} from "react";
import {authContext} from "../../../contexts";
import {IfoodCategory, IfoodCategoryItem} from "../../../types/types";
import {Catalog} from "../components/ifood/menu/ifoodCatalogs";
import IfoodMenuItem from "../components/ifood/menu/ifoodMenuItem";

interface IfoodContextI {
    menu?: IfoodCategory[],
    catalogs?: Catalog[],
    selectedProduct?: IfoodCategoryItem,
    Component?: ReactNode,
    selectProduct(product: IfoodCategoryItem): void,
    closeComponent(): void
}

const IfoodContext = createContext<IfoodContextI>({selectProduct: () => {}, closeComponent: () => {}})

const IfoodContextProvider:FC = (props) => {

    const [menu, setMenu] = useState<IfoodCategory[]>([])
    const [catalogs, setCatalogs] = useState<Catalog[]>([])
    const [selectedProduct, setSelectedProduct] = useState<IfoodCategoryItem | undefined>(undefined)
    const [Component, setComponent] = useState<ReactNode | undefined>(undefined)

    const selectProduct = (product: IfoodCategoryItem) => {

    }

    const closeComponent = () => {
        setComponent(undefined)
    }

    return (
        <IfoodContext.Provider value={{menu, catalogs, selectedProduct, Component, selectProduct, closeComponent}}>
            {props.children}
        </IfoodContext.Provider>
    )
}

export {IfoodContextProvider, IfoodContext}
