import React, {useState} from 'react';
import {Menu, Button, Layout} from 'antd';
import {ShopOutlined, UserOutlined, HistoryOutlined, ContainerOutlined,BarsOutlined, BarcodeOutlined} from '@ant-design/icons';
import {useHistory} from 'react-router';
import { Image } from 'antd';
import logo from "src/assets/logo/img.png"
import 'src/components/Menu/menu.css'
import {RightOutlined, LeftOutlined} from "@ant-design/icons"
import styled from "styled-components";
const { Sider } = Layout;


const SideNavDiv = styled.div`
  height: 100%;
  width: 100%;
  border-right: 1px solid #E7E7E7
`

const SideNavTrigger = (props: {collapsed: boolean}) => {
    const Icon = props.collapsed ? <RightOutlined /> : <LeftOutlined />
    return <SideNavDiv>{Icon}</SideNavDiv>
}

const SideNav = () => {
    const history = useHistory();

    const [collapse, setCollapse] = useState(true)
    const handleClick = (path: string) => () => {
        history.push(path);
    }

    return (
        <Sider collapsible collapsed={collapse} theme={"light"} trigger={<SideNavTrigger collapsed={collapse}/>}  onCollapse={() => setCollapse(collapse => !collapse)} style={{borderRight:"1px solid #E7E7E7"}}>
            <div style={styles.logoContainer}>
                <Image src={logo}  />
          </div>
                <Menu  theme={"light"} inlineCollapsed={collapse} mode="inline" style={{borderRight:"0px solid #E7E7E7",}}>
                    <Menu.Item key="1" icon={<BarsOutlined />} onClick={handleClick("/products")}>
                        <span> Produtos</span>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<BarcodeOutlined />} onClick={handleClick("/ingredients")}>
                        <span> Ingredientes</span>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<ContainerOutlined />} onClick={handleClick("/orders")}>
                        <span> Fila Pedidos</span>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<HistoryOutlined />} onClick={handleClick("/history")}>
                        <span> Histórico pedidos</span>
                    </Menu.Item>
                    <Menu.Item key="5" icon={<UserOutlined/>} onClick={handleClick("/clients")}>
                        <span> Clientes</span>
                    </Menu.Item>
                    <Menu.Item key="6" icon={<ShopOutlined />} onClick={handleClick("/company")}>
                        <span> Loja</span>
                    </Menu.Item>

                </Menu>

        </Sider>
    );
}

const styles = {
    logoContainer: {
        height: "32px", margin: "16px",display:'flex', alignItems:'center', justifyContent:'center'
    }
}

export default SideNav;
