import { Status } from "src/types/types"
import { Tag } from "antd"

interface IStatusTag {
    color:string | undefined
    name:string | undefined
} 

const StatusTag = (props: IStatusTag) => {
    return <Tag color={props.color} style={{fontSize: 15}}>{props.name}</Tag>

}
export default StatusTag;