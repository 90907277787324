import React from 'react';
import {useAuth} from "../../hooks";
import useFetchAwaitRefresh from "../../hooks/useFetchAwaitRefresh";
import {ClientsService} from 'src/services'
import {Client, ClientOrder} from "../../types/types";
import ClientsTable from "./components/clientsTable";

const Index = () => {

    const {cToken} = useAuth()

    const [clients, loading] = useFetchAwaitRefresh<ClientOrder[]>(() =>  ClientsService.getClients(cToken?.token), [])

    return (
        <div>
            <ClientsTable clients={clients} loading={loading}/>
        </div>
    );
};

export default Index;
