import React, {FC, ReactNode} from 'react';
import Images from "src/assets/integration"
import {Button, Typography, Image, Space} from "antd";
import {IfoodOrderItem, Order, Origin} from "../../../types/types";
import {formatMoney} from "../../../utils";
import {RightOutlined} from "@ant-design/icons"
import {SA} from "./orderHelpers";
import dayjs from "dayjs";
import {ItemText, OptionText} from "../../../components/OrderDetails/components/StyledDetails";
import StatusTag from "../../../components/StatusTag/StatusTag";


const OrderIntegrationLogo: FC<{ origin: Origin }> = (props) => {
    return (
        <div>
            <Image height={64} preview={false} width={64} style={{paddingTop: 4}} src={Images[props.origin]}/>
        </div>

    )
}

const OrderBody: FC<{ order: Order, actions?: ReactNode }> = (props) => {
    const orderDate = dayjs(props.order?.createdAt).format("H:mm")

    return (
        <div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center'}}>
                <div>
                    <ItemText noMargin={true}>{`Pedido nº ${props.order?.id}(id)`}</ItemText>
                    <OptionText noMargin={true}>{orderDate}</OptionText>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <OrderIntegrationLogo origin={props.order.origin}/>
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center'}}>
                    <p style={{marginBottom: 0, fontWeight: 'bold', paddingRight:16}}>{formatMoney(props.order.total)}   </p>
                    <p style={{marginBottom: 0, fontWeight: 'bold'}}> {props.order.orderItems.length} itens</p>
                </div>
                {props.actions}
            </div>
        </div>
    )
}

const OrderCard: FC<{ order: Order, hideItems: boolean, actions?: ReactNode }> = (props) => {
    return (
        <div style={{justifyContent: "space-between", alignItems: 'center',  borderBottom:"1px solid #E7E7E7", padding: 8}}>
            <OrderBody order={props.order} actions = {props.actions}/>
        </div>

    );
};

export default OrderCard;
