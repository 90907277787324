import React, {FC, ChangeEvent} from 'react'
import { Input, Form, message, Typography, Button, Space } from 'antd';
import DatePicker from 'src/components/DatePicker/DatePicker';
import dayjs, {Dayjs} from 'dayjs';
import {FastBackwardOutlined, CalendarOutlined} from '@ant-design/icons'
import { OrdersFilters } from './../../../services/orderService';


interface IFilterHistory {
    filterOrders(): void
    updateFilter: (key:string) => (value:string | null | Dayjs) => void
}

const FilterHistory:FC<IFilterHistory> = (props) => {
    const { Title } = Typography;
    const onChangeText = (key:string) => (event:ChangeEvent<HTMLInputElement>) =>{
        const updateFunction = props.updateFilter(key)
        updateFunction(event.target.value)
    }
    const onChangeDate = (key:string) => (date:Dayjs | null) =>{
        const updateFunction = props.updateFilter(key)
        updateFunction(date)
    }

    return(
        <>
        <div id="form" style={{display: 'flex'}}>
                <div id="inputs"style={{display:"flex", flexDirection:"row"}}>
                <div id="numero-pedido">
                        <Input name="orderNumber" size="large" style={{borderRadius:5}} onChange={onChangeText('orderNumber')}/>
                </div>
                <div id="date-picker1" style={{marginLeft:16}}>
                        <DatePicker name="beforeDate"defaultValue={dayjs(new Date())} format="DD/MM/YYYY" size="large" suffixIcon={<CalendarOutlined style={{color:"#ED5643"}}/>} onChange={onChangeDate("beforeDate")}/>
                </div>
                <div id="date-picker2" style={{marginLeft:16}}>
                        <DatePicker name="afterDate"defaultValue={dayjs(new Date())} format="DD/MM/YYYY" size="large"suffixIcon={<CalendarOutlined style={{color:"#ED5643"}}/>}onChange={onChangeDate("afterDate")}/>
                </div>
                </div>
                <div id="botao-consultar" >
         
                        <Button onClick={props.filterOrders}size="large" htmlType="submit" style={{backgroundColor:"#ED5643", color:"white"}}>
                            Consultar
                        </Button>
                 
                </div>
        </div>
    </>
    )

    }

export default FilterHistory;
