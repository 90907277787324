import axios from "axios"

const IngredientsAPI = axios.create({baseURL: "https://food-business-api.herokuapp.com/ingredients"})

class IngredientsService {
    
    getIngredients = async (cToken:string | undefined) => {
        const {data} = await IngredientsAPI.get("/", {
            headers:{
                Authorization:cToken
            }
        })
        return data
        
    }
    
}

export default new IngredientsService();