import React, {FC} from 'react';
import {IfoodCategory} from "src/types/types";
import ProductDndContainer from './../../../../../components/Products/dragndrop/ProductDndContainer';

const IfoodMenuCategories:FC<{menu: IfoodCategory[]}> = (props) => {
    return (
        <div>
            <ProductDndContainer categories={props.menu}/>
        </div>
    );
};

export default IfoodMenuCategories;
